@media print {
  #invoicePrintView,
  #paycheckPrintView {
    margin-top: 150;

    .print_lg_text {
      font-size: 48px!important;
    }

    .print_med_text {
      font-size: 28px!important;
    }

    .print_sm_text {
      font-size: 22px!important;
    }

    .print_logo {
      min-height: 125px;
      max-width: 200px;
    }

    .print_branding {
      font-size: 22px;
    }
  }

  #paycheckPrintView {
    width: 1000px!important;
  }
}
