.combine-pdf {
  margin: 0px;
}

.react-pdf__Page {
  max-width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0%;
  padding-bottom: 0%;
  text-align: center;
}

.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  max-width: 100%;
}